import { StyledSvg } from "baseui/icon";
import React from "react";

export const HelpIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.37747 7.48435C8.19385 7.65423 7.99008 7.84276 7.82192 8.06672C7.61817 8.33808 7.4667 8.66146 7.4667 9.06672H8.53337C8.53337 8.80646 8.7499 8.59259 9.10297 8.26672L9.10389 8.26587C9.56293 7.84149 10.1334 7.31411 10.1334 6.40006C10.1334 5.22352 9.17657 4.26672 8.00003 4.26672C7.18464 4.26672 6.47479 4.72629 6.11583 5.40006C5.95685 5.69848 5.8667 6.03891 5.8667 6.40006H6.93337C6.93337 5.81179 7.41177 5.33339 8.00003 5.33339C8.5883 5.33339 9.0667 5.81179 9.0667 6.40006C9.0667 6.82619 8.80537 7.08912 8.37923 7.48272L8.37747 7.48435ZM14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8ZM8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5ZM8.00003 12.0001C7.55843 12.0001 7.20003 11.6417 7.20003 11.2001C7.20003 10.7585 7.55843 10.4001 8.00003 10.4001C8.44163 10.4001 8.80003 10.7585 8.80003 11.2001C8.80003 11.6417 8.44163 12.0001 8.00003 12.0001Z"
    />
  </StyledSvg>
);
